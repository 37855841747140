import React, { useEffect } from 'react';
import { hasWindow, isBrowserSupported } from './utils';
import { orderBy } from './lodash-min';
import Layout from './components/Layout';
import BrowserNotSupported from './components/not-supported/BrowserNotSupported';
import Block from './components/Block';
import { DataContext, CaptchaProvider, useAnalytics, useSession } from 'dealer-website-components';
import { settings } from '../config/index.mjs';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import CustomBlock from './components/CustomBlock';
import { getAnalyticsTracker } from './analytics/analytics';
import { navigate, Link, Script, graphql } from 'gatsby';

// eslint-disable-next-line
import analyticstracker from 'analyticstracker';
// eslint-disable-next-line
import analyticsTransQA from 'analytics-transQA';
// eslint-disable-next-line
import analyticsTransGTM from 'analytics-transGTM';
import { capitalize, parseText } from './utils.mjs';
import useScript from './hooks/useScript';

const GatsbyLink = (props) => {
  const { children, url, options } = props;
  return (
    <Link to={url} {...options}>
      {children}
    </Link>
  );
};

export const Head = (props) => {
  const { params } = props;
  const { page, meta, favoriteIconUrl, language } = props.pageContext;
  const headScripts = settings.SCRIPTS.filter((s) => !s.body) || [];
  const fonts = settings.FONTS || [];

  const [loadedConsentScript] = useScript({ src: settings.CONSENT_SCRIPT_URL });

  const resolvePageMeta = () => {
    // Only need to apply specifics params on the productDetail page for now
    if (page.category != 'productDetail') {
      return {
        title: meta.title,
        description: meta.description
      };
    }

    let title = '';
    let description = '';

    const custom_meta = page.meta.find((meta) => meta.language.toLowerCase() == language);
    if (custom_meta) {
      const make = params?.make ? capitalize(params?.make) : '';
      const model = params?.model ? capitalize(params?.model) : '';
      title = parseText(custom_meta.title, { ...params, make, model }).replace(/\s\s+/g, ' ');
      description = parseText(custom_meta.description, { ...params, make, model }).replace(/\s\s+/g, ' ');
    } else {
      title = meta.title;
      description = meta.description;
    }
    return {
      title,
      description
    };
  };

  // Used to convert {{make}} - {{model}} in page meta from the product detail page
  const updatedMeta = resolvePageMeta();

  const renderGoogleTagManagerScript = (id) => {
    return `
      (function(w,d,s,l,i){
        w[l]=w[l]||[];
        w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
        var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })
      (window,document,'script','dataLayer','${id}');
    `;
  };

  const renderGoogleAnalyticsScript = (id) => {
    return `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${id}');
    `;
  };

  const siteData = {
    '@type': 'WebSite',
    name: settings.WEBSITE_NAME,
    url: `https://${settings.DOMAIN}`
  };

  return (
    <>
      <html lang={language} />
      <title>{updatedMeta.title}</title>
      <meta name='description' content={updatedMeta.description} />
      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(siteData)
        }}
      ></script>
      <meta name='google' content='notranslate' />

      {!page.indexable && <meta name='robots' content='noindex' />}

      <link rel='icon' href={favoriteIconUrl} />
      {/* {!settings.CONSENT && settings.CONSENT_SCRIPT_URL != '' && <Script type='text/javascript' src={settings.CONSENT_SCRIPT_URL} />} */}

      {settings.GOOGLE_SEARCH_CONSOLE_SITE_VERIFICATION != '' && <meta name='google-site-verification' content={settings.GOOGLE_SEARCH_CONSOLE_SITE_VERIFICATION} />}
      {settings.GOOGLE_ANALYTICS_IDENTIFICATION && <meta name='google-site-verification' content={settings.GOOGLE_ANALYTICS_IDENTIFICATION} />}

      {headScripts.map((headScript, index) => (
        <Script key={index} type='text/javascript' {...headScript} />
      ))}
      {fonts.map((font, index) => (
        <link key={index} {...font} />
      ))}

      {loadedConsentScript && settings.GOOGLE_ANALYTICS_ID && settings.GOOGLE_ANALYTICS_ID !== '' && <Script>{renderGoogleAnalyticsScript(settings.GOOGLE_ANALYTICS_ID)}</Script>}

      {loadedConsentScript && settings.GOOGLE_TAG_MANAGER_ID && (
        <>
          {typeof settings.GOOGLE_TAG_MANAGER_ID == 'string' && settings.GOOGLE_TAG_MANAGER_ID !== '' && <Script>{renderGoogleTagManagerScript(settings.GOOGLE_TAG_MANAGER_ID)}</Script>}
          {Array.isArray(settings.GOOGLE_TAG_MANAGER_ID) && settings.GOOGLE_TAG_MANAGER_ID.map((gtmId) => <Script key={gtmId}>{renderGoogleTagManagerScript(gtmId)}</Script>)}
        </>
      )}
    </>
  );
};

const Page = (props) => {
  const { page, componentContext } = props.pageContext;
  const { t } = useTranslation();
  const context = {
    ...componentContext,
    t: t,
    navigator: {
      navigate: (url, options) => {
        navigate(url, options);
      },
      component: GatsbyLink
    }
  };

  const { session } = useSession(context);
  const pageVariables = { reference: props.reference };
  const { location } = props;

  // New implementation of analytics
  const { initializeTracker, analyticsTracker } = useAnalytics(context);

  const onClickAdvertisementFunction = (e) => {
    if (hasWindow() && analyticsTracker !== undefined && e.currentTarget.dataset?.analyticsbuttonname) {
      const buttonEvent = analyticsTracker.buttonCtaEventInitialize(
        {
          analyticsButtonName: e.currentTarget.dataset?.analyticsbuttonname || '',
          analyticsButtonType: e.currentTarget.dataset?.analyticsbuttontype || 'custom-page-link',
          analyticsButtonCategory: e.currentTarget.dataset?.analyticsbuttoncategory || '',
          analyticsButtonText: e.currentTarget.dataset?.analyticsbuttontext || e.currentTarget.innerText || '',
          analyticsButtonTarget: e.currentTarget.dataset?.analyticsbuttontarget || e.currentTarget.href,
          analyticsButtonPosition: e.currentTarget.dataset?.analyticsbuttonposition || 'advertisement'
        },
        context
      );
      if (buttonEvent?.info?.buttonName) {
        analyticsTracker.analyticsEventSubmit(buttonEvent);
      }
    }
  };

  const handleAdvertisementIsAdded = (mutationsList) => {
    for (const mutation of mutationsList) {
      if (mutation.type === 'childList') {
        const addedAds = Array.from(mutation.addedNodes).filter(
          (node) =>
            node.nodeType === 1 && // Ensure it's an element node
            node.classList.contains('digex-advertisement')
        );

        if (addedAds.length > 0) {
          Array.from(addedAds).forEach((adv) => {
            if (!adv.onclick) adv.onclick = onClickAdvertisementFunction;
          });
        }
      }
    }
  };

  useEffect(() => {
    const observer = new MutationObserver(handleAdvertisementIsAdded);
    if (analyticsTracker === undefined) {
      return () => {
        observer.disconnect();
      };
    }

    const advertisements = document.getElementsByClassName('digex-advertisement');
    if (advertisements && advertisements.length > 0) {
      Array.from(advertisements).forEach((adv) => {
        if (!adv.onclick) adv.onclick = onClickAdvertisementFunction;
      });
    }
    observer.observe(document.body, {
      childList: true, // Look for addition/removal of child elements
      subtree: true // Monitor the entire subtree
    });
    // Cleanup on component unmount
    return () => {
      observer.disconnect();
    };
  }, [analyticsTracker]);

  useEffect(() => {
    if (page.type !== context.defaultPageTypes.vehicles && hasWindow()) {
      // by setting shouldUpdateScroll to false in gatbsy-browser.js (for stock page when changing filters) this is needed for all other pages..
      window.scrollTo(0, 0);
    }

    if (hasWindow() && !analyticsTracker) {
      const tracker = getAnalyticsTracker();
      initializeTracker(tracker);
      window.analyticsTracker = tracker;
    }
  }, []);

  useEffect(() => {
    if (hasWindow() && analyticsTracker) {
      // product_detail needs more data, all other pages send data imediatly
      // TODO not use hardcoded category names
      if (page.category !== 'productDetail' && page.category !== 'purchase') {
        const pageImpressionEvent = analyticsTracker.pageImpressionEventInitialize(page, undefined, context, session);
        if (pageImpressionEvent && pageImpressionEvent.info?.previousPageName !== pageImpressionEvent.info?.pageName) {
          // Only trigger a page event if the page is chaged
          analyticsTracker.analyticsEventSubmit(pageImpressionEvent);
        }
      }
    }
  }, [analyticsTracker]);

  // force authenticated on all pages (for myway-agent)
  useEffect(() => {
    if (settings.AUTHENTICATION && session && !session.authenticated && page.type !== 'login') {
      if (hasWindow()) {
        navigate(`/${context.culture}/${context.defaultPageNames.login}?redirect=${window.location.pathname}`);
      }
    }
  }, [session]);

  const browserSupported = isBrowserSupported();
  if (!browserSupported) {
    return (
      <div className='font-body bg-gray-100'>
        <BrowserNotSupported />
      </div>
    );
  }

  const resolveAnalyticsData = (type, data) => {
    if (hasWindow() && analyticsTracker) {
      const pageImpressionEvent = analyticsTracker.pageImpressionEventInitialize(page, data, context, session);
      if (pageImpressionEvent && pageImpressionEvent.info?.previousPageName !== pageImpressionEvent.info?.pageName) {
        // Only trigger a page event if the page is chaged
        analyticsTracker.analyticsEventSubmit(pageImpressionEvent);
      }

      if (type === 'vehicle') {
        const productDetail = analyticsTracker.productDetailInitialize({ vehicle: data }, context);
        analyticsTracker.analyticsEventSubmit(productDetail);
      }
    }
  };

  const orderedBlocks = orderBy(page.blocks, 'order');

  if ((settings.AUTHENTICATION && session === undefined) || (settings.AUTHENTICATION && session && !session.authenticated && page.type !== 'login')) {
    return <div>Loading</div>;
  }

  return (
    <Layout context={context}>
      <CaptchaProvider captchaKey={settings.CAPTCHA_KEY}>
        <DataContext.Consumer>
          {(value) => (
            <React.Fragment>
              {orderedBlocks.map((block) => {
                if (block.contentType !== 'CustomGeneratedBlockInBuilder') {
                  return <Block block={block} key={block.id} context={context} store={value} resolveAnalyticsData={resolveAnalyticsData} pageVariables={pageVariables} location={location} />;
                } else {
                  return <CustomBlock block={block} key={block.id} context={context} />;
                }
              })}
            </React.Fragment>
          )}
        </DataContext.Consumer>
      </CaptchaProvider>
    </Layout>
  );
};

export default Page;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
