import React, { useEffect } from 'react';
import { find } from '../lodash-min';
import InnerHTML from 'dangerously-set-html-content';
import { hasWindow } from '../utils.mjs';
import { useAnalytics } from 'dealer-website-components';

const CustomBlock = ({ block, context }) => {
  const { analyticsTracker } = useAnalytics(context);

  const getHtmlContent = (data, lng) => {
    const content = find(data, (d) => d.culture === lng);
    const html = content ? find(content.fields, (f) => f.type === 'html') : undefined;
    return html ? html.value : 'content not found';
  };

  const onClickFunction = (e) => {
    if (hasWindow() && analyticsTracker !== undefined) {
      const buttonEvent = analyticsTracker.buttonCtaEventInitialize(
        {
          analyticsButtonName: e.target.dataset?.analyticsbuttonname || '',
          analyticsButtonType: e.target.dataset?.analyticsbuttontype || 'custom-page-link',
          analyticsButtonCategory: e.target.dataset?.analyticsbuttoncategory || '',
          analyticsButtonText: e.target.dataset?.analyticsbuttontext || e.target.innerText || '',
          analyticsButtonTarget: e.target.dataset?.analyticsbuttontarget || e.target.href,
          analyticsButtonPosition: e.target.dataset?.analyticsbuttonposition || 'CustomBlock'
        },
        context
      );
      if (buttonEvent?.info?.buttonName) {
        analyticsTracker.analyticsEventSubmit(buttonEvent);
      }
    }
  };

  useEffect(() => {
    if (analyticsTracker === undefined) return;
    const links = document.getElementById(`b${block.id.split('-')[0]}`).getElementsByTagName('a');
    if (!links) return;

    Array.from(links).forEach((linkElement) => {
      if (linkElement.dataset?.analyticsbuttonname) {
        linkElement.onclick = onClickFunction;
      }
    });
  }, [analyticsTracker]);

  return (
    <div id={`b${block.id.split('-')[0]}`}>
      {/*Using InnerHTML instead of dangerouslySetInnerHTML because 'inline javascript' sometimes not working! */}
      <InnerHTML html={getHtmlContent(block.content, context.culture)} />
      {/*<div dangerouslySetInnerHTML={{__html: getHtmlContent(block.content, context.culture)}}/>*/}
    </div>
  );
};
export default CustomBlock;
